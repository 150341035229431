<template>
  <section>
    <v-card-title>
      <slot name="search"/>
    </v-card-title>
    <v-card-title v-if="!loading">
      <slot name="filter"/>
    </v-card-title>

    <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
    >
      <template></template>
      <template v-slot:loading>
        {{
          errorMessage !== ''
          ? errorMessage
          : $t('user.list.datatable.loadingMessage')
        }}
      </template>

      <template v-slot:no-data>
        {{
          errorMessage !== '' ? errorMessage : $t('user.list.datatable.noData')
        }}
      </template>

      <template v-slot:item.firstName="{ value }">
        <span v-if="value === ''" class="no__data">
          {{ $t('user.list.datatable.informations.anonymous') }}
        </span>
        <span v-else>{{ value }}</span>
      </template>

      <template v-slot:item.lastName="{ value }">
        <span v-if="value === ''" class="no__data">
          {{ $t('user.list.datatable.informations.anonymous') }}
        </span>
        <span v-else>{{ value }}</span>
      </template>

      <template v-slot:item.referent="{ value }">
        <span v-if="value === ''" class="no__data">
          {{ $t('user.list.datatable.informations.noReferent') }}
        </span>
        <span v-else>{{ value }}</span>
      </template>

      <template v-slot:item.phone="{ value }">
        <span v-if="value === ''" class="no__data">
          {{ $t('user.list.datatable.informations.noPhone') }}
        </span>
        <span v-else>{{ value }}</span>
      </template>

      <template v-slot:item.region="{ value }">
        <span v-if="value === ''" class="no__data">
          {{ $t('user.list.datatable.informations.noRegion') }}
        </span>
        <span v-else>{{ value }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <v-icon>{{ statusIcon(item) }}</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
                @click="onOpenResetPasswordDialog(item)"
            >
              <u-icon name="nv-lock-unlock"/>
            </v-btn>
          </template>
          <span>{{ $t('user.list.datatable.actions.resetPassword') }}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="isAdmin"
                text
                v-bind="attrs"
                v-on="on"
                :disabled="myProfile(item)"
                @click="onSwitchStatus(item, false)"
            >
              <u-icon name="nv-user-block"/>
            </v-btn>
          </template>
          <span>{{ $t('user.list.datatable.actions.deactivate') }}</span>
        </v-tooltip>

        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="isAdmin"
                text
                v-bind="attrs"
                v-on="on"
                @click="onSwitchStatus(item, true)"
            >
              <u-icon name="nv-user-check"/>
            </v-btn>
          </template>
          <span>{{ $t('user.list.datatable.actions.activate') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
                v-if="!isResearcher"
                @click="$emit('update-user', item)"
            >
              <u-icon name="nv-user-edit"/>
            </v-btn>
          </template>
          <span>{{ $t('user.list.datatable.actions.update') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="isPatient(item)"
                :to="{ name: 'DashboardPatient', params: { patientId: item.id } }"
                text
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-card-account-details-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('user.list.datatable.actions.dashboardPatient') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="isPatient(item)"
                :to="{ name: 'SummaryPatient', params: { patientId: item.id } }"
                text
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('user.list.datatable.actions.summaryPatient') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <confirm-dialog
        v-model="resetDialog.display"
        :body="
        $t('user.list.datatable.resetPassword.confirm.label', {
          name: resetDialog.user.username,
        })
      "
        :title="$t('user.list.datatable.resetPassword.confirm.title')"
        @valid="onValidResetPassword"
        @cancel="onCloseResetPasswordDialog"
    />
  </section>
</template>

<script>
import { myStatus } from '@/store/modules/User'
import { mapActions, mapGetters } from 'vuex'
import { JWTTranslation } from '@/store/modules/Security'

export default {
  name: 'AdminUserTable',

  components: {
    ConfirmDialog: () => import('@/components/Template/ConfirmDialog'),
  },

  props: {
    errorMessage: {
      required: false,
      type: String,
      default: '',
    },
    items: {
      required: true,
      type: Array,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    search: {
      required: false,
      type: String,
      default: '',
    },
  },

  data () {
    return {
      resetDialog: {
        display: false,
        user: { username: '' },
      },
    }
  },

  computed: {
    ...mapGetters(['groups', 'groupsId', 'users']),
    isAdmin () {
      return myStatus(['ADM'])
    },
    isResearcher () {
      return myStatus(['CHR'])
    },
    headers () {
      return [
        {
          text: this.$t('user.list.datatable.headers.username'),
          value: 'username',
        },
        { text: this.$t('user.list.datatable.headers.group'), value: 'groups' },
        {
          text: this.$t('user.list.datatable.headers.firstName'),
          value: 'firstName',
        },
        {
          text: this.$t('user.list.datatable.headers.lastName'),
          value: 'lastName',
        },
        { text: this.$t('user.list.datatable.headers.phone'), value: 'phone' },
        {
          text: this.$t('user.list.datatable.headers.referent'),
          value: 'referent',
        },
        {
          text: this.$t('user.list.datatable.headers.region'),
          value: 'region',
        },
        {
          text: this.$t('user.list.datatable.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('user.list.datatable.headers.actions'),
          value: 'actions',
        },
      ]
    },
  },

  methods: {
    ...mapActions(['displaySnackbar', 'recoveryPassword', 'updateUser']),
    isPatient ({ groupsId }) {
      return this.groups.length !== 0
             ? groupsId.includes(this.groupsId.PAT)
             : false
    },
    myProfile ({ id }) {
      return (
          id === JWTTranslation(localStorage.getItem('JWT_ACCESS')).body.userId
      )
    },
    onCloseResetPasswordDialog () {
      this.resetDialog.user = { username: '' }
      this.resetDialog.display = false
    },
    onOpenResetPasswordDialog (item) {
      this.resetDialog.user = item
      this.resetDialog.display = true
    },
    onSwitchStatus (userUpdated, newStatus) {
      this.$emit('update:loading', true)
      this.updateUser({
        user: userUpdated,
        data: { is_active: newStatus },
      })
          .then(() => {
            this.$emit('user-status-updated', { userUpdated, newStatus })
            this.$emit('update:loading', false)
            let msg = newStatus
                      ? this.$t('user.statusUpdate.success.active', {
                  username: userUpdated.username,
                })
                      : this.$t('user.statusUpdate.success.inactive', {
                  username: userUpdated.username,
                })
            this.displaySnackbar({
              status: true,
              type: 'success',
              text: msg,
            })
          })
          .catch(error => {
            this.displaySnackbar({
              status: true,
              type: 'error',
              text: error.response.data.errors[0],
            })
            this.$emit('update:loading', false)
          })
    },
    onValidResetPassword () {
      this.recoveryPassword({
        username: this.resetDialog.user.username,
        noRedirect: true,
      })
          .then(() => {
            this.resetDialog.display = false
          })
    },
    statusIcon ({ status }) {
      return status ? 'mdi-check-bold' : 'mdi-close-thick'
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  .v-card__title::v-deep {
    i {
      font-size: 30px;
    }
  }

  .v-data-table::v-deep {
    td,
    th {
      font-size: 18px;

      i {
        font-size: 25px;
      }

      .no__data {
        font-style: italic;
        color: grey;
      }
    }
  }
}
</style>
